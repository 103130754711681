<template>
  <div class="lightbox">
    <div class="lightbox-container">
      <img
        src="../../assets/images/icons/close_icon.svg"
        alt="Lightbox close icon"
        v-on:click="close()"
      />
      <div class="lightbox-container-header">
        <h2>{{ title }}</h2>
      </div>
      <div class="lightbox-container-content">
        <SubscriptionLightbox
          :user="lightbox.data.user"
          v-if="lightbox.view === lightboxes.Subscriptions"
          v-on:setLightboxInfo="setLightboxInfo"
        />

        <AdminUserProfileLightbox
          :user="lightbox.data.user"
          v-if="lightbox.view === lightboxes.AdminUserProfile"
          v-on:setLightboxInfo="setLightboxInfo"
        />

        <SubscriptionExpiredLightbox
          :lightbox-data="lightbox.data"
          :refreshText="lightbox.data.refreshText"
          v-if="lightbox.view === lightboxes.Expired"
          v-on:setLightboxInfo="setLightboxInfo"
        />

        <AgreementsLightbox
          :showButtons="lightbox.data.showButtons"
          :terms="lightbox.data.terms"
          :refreshText="lightbox.data.refreshText"
          v-if="lightbox.view === lightboxes.Agreements"
          v-on:setLightboxInfo="setLightboxInfo"
          v-on:logout="logout"
          v-on:close="close"
        />

        <ProfileEditLightbox
          v-if="lightbox.view === lightboxes.UserProfileEdit"
          v-on:setLightboxInfo="setLightboxInfo"
          v-on:close="close"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/utils/EventBus.js";
import { Lightboxes } from "@/utils/Constants";

export default {
  name: "Lightbox",
  components: {
    SubscriptionLightbox: () =>
      import("@/components/lightboxes/SubscriptionLightbox"),
    SubscriptionExpiredLightbox: () =>
      import("@/components/lightboxes/SubscriptionExpiredLightbox"),
    AdminUserProfileLightbox: () =>
      import("@/components/lightboxes/AdminUserProfileLightbox"),
    AgreementsLightbox: () =>
      import("@/components/lightboxes/AgreementsLightbox"),
    ProfileEditLightbox: () =>
      import("@/components/lightboxes/ProfileEditLightbox"),
  },
  props: {
    lightbox: {
      type: Object,
      default() {
        return {
          view: -1,
          data: {},
        };
      },
    },
  },
  data() {
    return {
      lightboxes: Lightboxes,
      title: "",
      childEvent: {},
    };
  },
  mounted() {
    this.childEvent = {};
  },
  methods: {
    setLightboxInfo(info) {
      this.title = info.title;
    },
    setLightboxChildEvent(event) {
      this.childEvent = event;
    },
    forwardChildEvent() {
      this.$emit(this.childEvent.type, this.childEvent.val);
      this.close();
    },
    close() {
      EventBus.$emit("close-lightbox", {});
    },
    logout() {
      this.$emit("logout");
    },
  },
};
</script>

<style lang="scss">
.lightbox {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);

  &-container {
    width: 960px;
    max-height: 80vh;
    overflow-y: auto;
    margin: 0px auto;
    padding: 60px;
    background-color: #fff;
    border-radius: 15px;
    position: relative;

    img {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    &-header {
      margin-bottom: 25px;
    }
  }
}
</style>
